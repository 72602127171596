import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import Link from '../../components/Common/Link'

import { useTranslations } from '../../hooks/use-translations'
import { PathEnum } from '../../types/pages'

import * as s from '../../pages/news.module.scss'

const SignalMadeDocs: React.FC = () => {
  const title = 'SIGNAL выпустил новый модуль - SIGNAL DOCS'
  const { getPath } = useTranslations()
  return (
    <>
      <SEO
        title={title}
        description="Среда общих данных (CDE) в BIM для строительства на своих серверах, с поддержкой мобильной версии, для работы со смартфонов и планшетов на стройке."
      />
      <NewsSection
        title={title}
        dateData={{
          date: '07.06.2023',
        }}
        rayContent
      >
        <p className={s.text}>
          7 июня SIGNAL выпустил свой новый модуль - <b>SIGNAL DOCS.</b>
        </p>
        <p className={s.text}>
          SIGNAL DOCS - это Среда общих данных на своих серверах и в смартфоне.
          Другими словами это облачное хранилище для файлообмена и хранения
          данных на проекте, с поддержкой версионности и ролевой системы
          доступа. В систему можно загружать любые форматы файлов, любого
          размера, просматривать PDF-документы и редактировать Google документы.
          В ближайшее время будут добавлены вьювер для просмотра изображений и
          функционал по просмотру CAD/BIM данных через Forge-вьювер.
        </p>
        <StaticImage
          width={1160}
          className={s.imageBlock}
          src="../../assets/images/news/2023-06-07-signal-made-docs/image-1.png"
          alt={title}
          title={title}
          placeholder="blurred"
        />
        <p className={s.text}>
          {`Подключение и настройка системы занимают не более 5 минут, можно сосмартфона, зарегистрировавшись на `}
          <Link href="https://hub.sgnl.pro" target="_blank">
            https://hub.sgnl.pro
          </Link>
          {` и\u00A0отправив заявку с сайта `}
          <GatsbyLink to={getPath(PathEnum.Docs)} className="has-text-primary">
            https://sgnl.pro/docs
          </GatsbyLink>
          {` или написав запрос по почте `}
          <Link href="support@sgnl.pro" typeLink="email">
            support@sgnl.pro
          </Link>
          .
        </p>
        <p className={s.text}>
          Главным преимуществом SIGNAL DOCS является возможность размещать
          данные на своих локальных серверах, через установку и настройку своего
          S3 хранилища. Естественно, если пользователю это не требуется, то по
          умолчанию данные хранятся на серверах SIGNAL.
        </p>
        <p className={s.text}>
          Еще одна особенность SIGNAL - что интерфейс адаптирован под мобильные
          устройства. Это позволяет администрировать проекты, загружать
          документацию и фото прямо со смартфона, а также получить доступ к
          рабочей документации с любого устройства по логину и паролю за
          считанные секунды без установки дополнительных приложений. Но не
          смотря на это в планах есть и разработка мобильной версии, для
          возможности работы без интернета.
        </p>
        <p className={s.text}>
          А что по деньгам? Заявленная цена - 10 000 ₽ / за пользователя / в
          год. Обычно достаточно 15-25 лицензий на 1 проект. Можно делать
          технические учетки, например если хотите выделить одну лицензию на
          какую-то общую почту компании подрядчика (в таком случае одновременная
          работа из под одной учетки с разных устройств возможна), но в итоге
          компании приходят к персональным учеткам всем сотрудникам, т.к. всем
          надо давать разные доступы и персонально подключать к процессам
          согласования. Поэтому для крупных клиентов мы сделали Enterprise
          версию (100 пользователей) после которой включается режим
          неограниченного количества лицензий.
        </p>
        <StaticImage
          width={1160}
          className={s.imageBlock}
          src="../../assets/images/news/2023-06-07-signal-made-docs/image-2.png"
          alt={title}
          title={title}
          placeholder="blurred"
        />
        <p className={s.text}>
          В настоящее время функционал системы позволяет организовать файловый
          облачный сервер компании, обеспечив доступ согласно ролей, просмотр
          PDF документации, а также загрузку и скачивание других файлов. Это
          позволяет организовать передачу документации Заказчику на проверку, а
          также на строительную площадку для работы строителей и строительного
          контроля со смартфонов или планшетов.
        </p>
        <p className={s.text}>
          Дальше планируется за июнь встроить в систему часть модуля VALUE -
          QR-коды, ЭЦП и штампы в производство работ и подключить Forge-вьювер
          для просмотра САПР и BIM форматов, настроить подписку на папки и
          оповещения.
        </p>
        <p className={s.text}>
          По дорожной карте разработки продукта на июль и август запланированы
          модули Согласования и Замечания для PDF-документации. Рассматриваем
          интеграцию с отечественными BIM-вьюверами и редакторами офисных
          документов.
        </p>
        <p className={s.text}>
          {`Для всех текущих клиентов лицензии DOCS будут добавлены бесплатно в уже купленные пакеты в количестве купленных лицензий. За\u00A0подключением обращайтесь на почту `}
          <Link href="info@sgnl.pro" typeLink="email">
            info@sgnl.pro
          </Link>
          .
        </p>
      </NewsSection>
    </>
  )
}

export default SignalMadeDocs
